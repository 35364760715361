import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { filterNames } from '../../util';
import { Button } from '../';
import styles from './PlaceInfo.module.css';

const tagCategories = ['food', 'drinks', 'activity'];

const googleMapsUrl = 'https://www.google.com/maps/dir/?api=1&destination='

function PlaceInfo({ address, phone, tags, website }) {
    const { state } = useLocation();
    const baseParams = new URLSearchParams(state);
    const baseSearch = filterNames
        .filter(filterName => filterName.variant === 'single')
        .map(filterName => baseParams.has(filterName.id) ? { [filterName.id]: baseParams.get(filterName.id) } : null)
        .reduce((acc, filter) => acc = { ...acc, ...filter }, {
            ...(baseParams.has('center') && { center: baseParams.get('center') })
        });

    const onLaunchDirections = () => {
        const encodedPlace = encodeURIComponent(`${address.street}, ${address.city}, ${address.state} ${address.zip}`);
        window.open(googleMapsUrl + encodedPlace, '_blank');
    };

    return (
        <div className={styles.root}>
            <Button text="Launch Directions" onClick={onLaunchDirections} variant="secondary" block />
            <h3 className={styles.heading}>Information</h3>
            <ul className={styles.list}>
                {address.street && <li>{address.street}</li>}
                {address.city && address.state && address.zip && <li>{address.city}, {address.state} {address.zip}</li>}
                {website && <li><a href={website}>{website.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').replace(/\/$/i, '')}</a></li>}
                {phone && <li><a href={`tel:${phone}`}>{phone}</a></li>}
            </ul>
            <h3 className={styles.heading}>Tags</h3>
            <ul className={styles.list}>
                {
                    tagCategories.map(category => tags[category].map(tag => {
                        const params = new URLSearchParams({ ...baseSearch, [category]: tag });
                        return (
                            <li key={tag}>
                                <Link to={'/map?' + params.toString()}>{tag}</Link>
                            </li>
                        )
                    }))
                }
            </ul>
        </div>
    );
}

export default PlaceInfo;
