import React from 'react';
import cx from 'classnames';
import { useTheme } from '../../util';
import { IconButton } from '../';
import styles from './SearchBar.module.css';

function SearchBar({ onClose, onQueryChanged }) {
    const theme = useTheme();

    const onChange = e => onQueryChanged(e.target.value);

    return (
        <div className={cx(styles.root, { [styles.dark]: theme === 'dark' })}>
            <input className={styles.input} onChange={onChange} placeholder="Search by name" />
            <IconButton iconName="close" onClick={onClose} variant="transparent" noHover />
        </div>
    );
}

export default SearchBar;
