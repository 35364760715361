import React from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { PlaceDescription, PlaceInfo } from '../../components';
import { usePlace } from '../../util';
import styles from './PlacePage.module.css';

function PlacePage() {
    const { state } = useLocation();
    const { id } = useParams();
    const place = usePlace(id);

    if (!place) {
        return null;
    }

    return (
        <div className={styles.root}>
            <div className={styles.back}>
                <div className={styles.backInner}>
                    <Link to={'/map' + (state || '')}>Back to search results</Link>
                </div>
            </div>
            <div className={styles.content}>
                <h2 className={styles.name}>{place.name}</h2>
                <div className={styles.description}>
                    <PlaceDescription
                        description={place.description}
                        image={place.image}
                        name={place.name}
                    />
                </div>
                <div className={styles.info}>
                    <PlaceInfo
                        address={place.address}
                        phone={place.phone}
                        tags={place.tags}
                        website={place.website}
                    />
                </div>
            </div>
        </div>
    );
}

export default PlacePage;
