import React from 'react';

import { ReactComponent as ArrowRight } from '../../assets/arrow_right.svg';
import { ReactComponent as ChevronDown } from '../../assets/chevron_down.svg';
import { ReactComponent as ChevronLeft } from '../../assets/chevron_left.svg';
import { ReactComponent as ChevronUp } from '../../assets/chevron_up.svg';
import { ReactComponent as Close } from '../../assets/close.svg';
import { ReactComponent as Locator } from '../../assets/locator.svg';
import { ReactComponent as Minus } from '../../assets/minus.svg';
import { ReactComponent as Plus } from '../../assets/plus.svg';
import { ReactComponent as Refresh } from '../../assets/refresh.svg';
import { ReactComponent as Search } from '../../assets/search.svg';

const iconNames = {
    arrowRight: ArrowRight,
    chevronDown: ChevronDown,
    chevronLeft: ChevronLeft,
    chevronUp: ChevronUp,
    close: Close,
    locator: Locator,
    minus: Minus,
    plus: Plus,
    refresh: Refresh,
    search: Search
}

function Icon({ iconName }) {
    return React.createElement(iconNames[iconName], { style: { verticalAlign: 'middle' }});
}

export default Icon;
