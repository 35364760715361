import { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useOptions } from './';

export const filterNames = [
    { id: 'city', title: 'City', default: 'New York City', variant: 'single' },
    { id: 'reviewed', title: 'Reviewed', default: 'Reviewed', variant: 'single' },
    { id: 'food', title: 'Food' },
    { id: 'drinks', title: 'Drinks' },
    { id: 'activity', title: 'Good for...' },
];

function useFilters() {
    const history = useHistory();
    const options = useOptions();
    const { search } = useLocation();

    return useMemo(() => {
        const params = new URLSearchParams(search);

        const filters = filterNames.reduce((filters, filter) => {
            const values = params.get(filter.id);
            if (filter.variant === 'single') {
                filters[filter.id] = values || filter.default;
            } else {
                filters[filter.id] = values ? values.split(',').filter(value => options[filter.id].includes(value)) : [];
            }
            return filters;
        }, { });
    
        const clear = () => {
            filterNames.forEach(({ id }) => params.delete(id));
            history.replace({ search: params.toString() });
        };
        const set = (filter, values) => {
            values && values.length !== 0 ? params.set(filter, values) : params.delete(filter);
            if (filter === 'city') {
                params.delete('center');
            }
            history.replace({ search: params.toString() });
        };
    
        return {
            filters,
            clear,
            set
        };
    }, [history, options, search]);
}

export default useFilters;
