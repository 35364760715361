import React, { useState } from 'react';
import cx from 'classnames';
import { useFilters, useOptions } from '../../util';
import { Button, Dropdown, Icon, MenuItem, RadioButton } from '../';
import styles from './Filter.module.css';

function SingleFilter({ id, isOpen, onClick }) {
    const { filters, set } = useFilters();
    const filter = filters[id];
    const options = useOptions()[id];
    const [state, setState] = useState(filter);
    
    const icon = isOpen ? 'chevronUp' : 'chevronDown';

    const onApply = () => {
        set(id, state);
        onClick(null);
    };
    const onOptionChanged = value => {
        setState(value);
    };

    return (
        <MenuItem>
            <span className={cx(styles.root, { [styles.active]: isOpen })} onClick={() => onClick(id)}>
                <span className={styles.title}>{filter} <Icon iconName={icon} /></span>
                <span className={styles.selected}></span>
            </span>
            <Dropdown isOpen={isOpen}>
                <div className={styles.dropdown}>
                    <Button
                        disabled={state === filter}
                        onClick={onApply}
                        text="Apply"
                        variant="secondary"
                        fullWidth
                        block
                    />
                    <div className={styles.selections}>
                        {
                            options.map((option) => (
                                <RadioButton
                                    key={option}
                                    isChecked={option === state}
                                    label={option}
                                    onChange={() => onOptionChanged(option)}
                                />
                            ))
                        }
                    </div>
                </div>
            </Dropdown>
        </MenuItem>
    );
}

export default SingleFilter;
