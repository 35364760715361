import React from 'react';
import { useFilteredResults } from '../../util';
import ListItem from './ListItem';
import styles from './List.module.css';

function List() {
    const places = useFilteredResults();

    return (
        <div className={styles.root}>
            {
                places.length === 0 ? (
                    <div className={styles.noResults}>
                        <p className={styles.noResultsHeader}>Sorry, no results in this area!</p>
                        <p className={styles.noResultsBody}>Try zooming out or clearing your filters.</p>
                    </div>
                ) : places.map(place => (
                    <ListItem
                        key={place.id}
                        description={place.description}
                        favorite={place.favorite}
                        id={place.id}
                        image={place.image}
                        name={place.name}
                    />
                ))
            }
        </div>
    );
}

export default List;
