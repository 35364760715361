import React from 'react';
import cx from 'classnames';
import styles from './Image.module.css';

function Image({ alt, border, height, src }) {
    return (
        <img className={cx(styles.root, { [styles.border]: border })} alt={alt} style={{ height }} src={src} />
    );
}

export default Image;
