import React from 'react';
import MultiFilter from './MultiFilter';
import SingleFilter from './SingleFilter';

function Filter({ variant, ...props }) {
    if (variant === 'single') {
        return <SingleFilter {...props} />;
    }

    return <MultiFilter {...props} />
}

export default Filter;
