import { useEffect, useState } from 'react';
import { API_URL } from '.';

const cache = { };

function usePlace(id) {
    const [results, setResults] = useState();
    useEffect(() => {
        if (cache[id]) {
            setResults(cache[id]);
        } else {
            fetch(API_URL + '/api/places/' + id)
            .then(res => res.json())
            .then(place => {
                place.tags.activity = place.tags.activity.filter(activity => activity !== 'Unreviewed');
                cache[id] = place;
                setResults(place);
            });
        }
    }, [id]);

    return results;
}

export default usePlace;
