import React, { useCallback, useEffect, useRef, useState } from 'react';
import { filterNames, useFilters } from '../../util';
import { Menu, MenuItem } from '../';
import Filter from './Filter';
import styles from './Filters.module.css';

function Filters() {
    const { clear } = useFilters();
    const [open, setOpen] = useState(null);

    const ref = useRef();
    const onClickOutside = useCallback((e) => {
        if (ref.current && !ref.current.contains(e.target)) {
            setOpen(null);
        }
    }, []);
    useEffect(() => {
        document.addEventListener('mousedown', onClickOutside);
        return () => document.removeEventListener('mousedown', onClickOutside);
    }, [onClickOutside]);

    const onClick = filter => {
        setOpen(open === filter ? null : filter);
    };
    const onClear = () => {
        setOpen(null);
        clear();
    }

    return (
        <Menu ref={ref}>
            {
                filterNames.map(filter => (
                    <Filter
                        key={filter.id}
                        {...filter}
                        isOpen={open === filter.id}
                        onClick={onClick}
                    />
                ))
            }
            <MenuItem onClick={onClear}>
                <span className={styles.clear}>Clear Filters</span>
            </MenuItem>
        </Menu>
    );
}

export default Filters;
