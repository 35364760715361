import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Button, List, Map, MobileFilters, Pivot } from '../../components';
import { filterNames, SelectionProvider, useFilters, useSelection } from '../../util';
import styles from './MobileMapPage.module.css';

const pivotOptions = [
    { id: 'map', name: 'Map' },
    { id: 'list', name: 'List' }
];

function MobileMapPage() {
    const [pivot, setPivot] = useState('map');
    const [isFiltersOpen, setIsFiltersOpen] = useState(false);
    const { filters } = useFilters();

    const numSelected = filterNames
        .filter(filter => filter.variant !== 'single')
        .reduce((acc, filter) => acc + filters[filter.id].length, 0);

    return (
        <div className={styles.root}>
            <SelectionProvider>
                <div className={styles.controls}>
                    <SelectionPivot active={pivot} onChange={option => setPivot(option)} options={pivotOptions} />
                    <span className={styles.filter}>
                        <Button onClick={() => setIsFiltersOpen(true)} text="Filter Results" badge={numSelected} variant="small" />
                        {isFiltersOpen && <MobileFilters onClose={() => setIsFiltersOpen(false)} />}
                    </span>
                </div>
                <div className={styles.content}>
                    {pivot === 'map' ? <Map city={filters.city} /> : <List />}
                </div>
            </SelectionProvider>
        </div>
    );
}

function SelectionPivot({ active, onChange, options}) {
    const { selected, setSelected } = useSelection();
    if (selected && active === 'list') {
        return <Redirect to={{ pathname: `/places/${selected}`, state: window.location.search }} />;
    }

    const onPivotChanged = option => {
        setSelected(null);
        onChange(option);
    }

    return <Pivot active={active} onChange={onPivotChanged} options={options} />;
}

export default MobileMapPage;
