import { useEffect, useState } from 'react';
import { API_URL } from '.';

const cache = { };

function useQuery(query) {
    const [results, setResults] = useState([]);
    useEffect(() => {
        if (query && query.length >= 3) {
            if (cache[query]) {
                setResults(cache[query]);
            } else {
                fetch(API_URL + '/api/search?query=' + encodeURIComponent(query))
                .then(res => res.json())
                .then(places => {
                    cache[query] = places;
                    setResults(places);
                });
            }
        } else {
            setResults([]);
        }
    }, [query]);

    return results;
}

export default useQuery;
