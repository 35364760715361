import React from 'react';
import cx from 'classnames';
import styles from './Button.module.css';

function Button({ block, disabled, fullWidth, onClick, text, variant = 'primary', badge }) {
    const styleOptions = {
        [styles.block]: block,
        [styles.fullWidth]: fullWidth,
        [styles.primary]: variant === 'primary',
        [styles.secondary]: variant === 'secondary',
        [styles.small]: variant === 'small'
    };
    
    return (
        <button
            className={cx(styles.root, styleOptions)}
            disabled={disabled}
            onClick={onClick}
        >
            {text}
            {badge > 0 && (
                <span className={styles.badge}>{badge}</span>
            )}
        </button>
    );
}

export default Button;
