import React from 'react';
import styles from './RadioButton.module.css';

function RadioButton({ isChecked, label, onChange }) {
    const handleChange = e => onChange && onChange(e.target.checked);

    return (
        <label className={styles.root}>
            <input
                className={styles.radio}
                type="radio"
                checked={isChecked}
                onChange={handleChange}
            />
            <span className={styles.label}>{label}</span>
        </label>
    );
}

export default RadioButton;
