import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTheme, useViewport } from '../../util';
import { Icon, IconButton, Search } from '../';
import styles from './Header.module.css';

function Header() {
    const [isSearchOpen, setIsSearchOpen] = useState(false);
    const viewport = useViewport();
    const location = useLocation();
    const theme = useTheme();
    useEffect(() => setIsSearchOpen(false), [location]);

    const buttonVariant = theme === 'dark' ? 'white' : undefined;

    if (viewport === 'mobile' && isSearchOpen) {
        return (
            <header className={styles.root}>
                <div className={styles.search}>
                    <Search onClose={() => setIsSearchOpen(false)} />
                </div>
            </header>
        );
    }

    if (viewport === 'mobile' && location.pathname.startsWith('/places')) {
        return (
            <header className={styles.root}>
                <div>
                    <Link to={'/map' + (location.state || '')}>
                        <Icon iconName="chevronLeft" />
                        <span style={{verticalAlign: 'middle'}}>Back</span>
                    </Link>
                </div>
                <div className={styles.search}>
                    <IconButton iconName="search" onClick={() => setIsSearchOpen(true)} variant={buttonVariant} />
                </div>
            </header>
        );
    }

    return (
        <header className={styles.root}>
            {viewport === 'desktop' && <div />}
            <h1 className={styles.logo}><Link to="/map">mango</Link></h1>
            <div className={styles.search}>
                {
                    isSearchOpen ?
                    <Search onClose={() => setIsSearchOpen(false)} /> :
                    <IconButton iconName="search" onClick={() => setIsSearchOpen(true)} variant={buttonVariant} />
                }
            </div>
        </header>
    );
}

export default Header;
