import React from 'react';
import cx from 'classnames';
import { useTheme } from '../../util';
import styles from './ThemeContainer.module.css';

function ThemeContainer({ children }) {
    const theme = useTheme();

    return (
        <div className={cx({ [styles.dark]: theme === 'dark' })}>
            {children}
        </div>
    );
}

export default ThemeContainer;
