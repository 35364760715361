import { useLocation } from 'react-router-dom';

function useTheme() {
    const location = useLocation();

    if (location.pathname === '/') {
        return 'dark';
    }

    return 'light';
}

export default useTheme;
