import React, { useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import ReactDOM from 'react-dom';
import { GoogleMap, LoadScript, useGoogleMap } from '@react-google-maps/api';
import { useFilteredResults, useResults, useSelection } from '../../util';
import { Locator, Refresh, Zoom } from './Controls';
import InfoWindow from './InfoWindow';
import Marker from './Marker';
import MyLocation from './MyLocation';

const defaults = {
    "New York City": { lat: 40.716616, lng: -73.980487 },
    "San Francisco": { lat: 37.786676, lng: -122.427646 }
};

const options = {
    fullscreenControl: false,
    mapTypeControl: false,
    streetViewControl: false,
    styles: [
        {
            featureType: 'poi',
            stylers: [{ visibility: 'off' }]
        },
        {
            featureType: 'transit',
            stylers: [{ visibility: 'off' }]
        }
    ],
    zoomControl: false
};

const onLoad = map => {
    const zoom = document.createElement('div');
    ReactDOM.render(<Zoom map={map} />, zoom);
    map.controls[window.google.maps.ControlPosition.RIGHT_BOTTOM].push(zoom);

    // const locator = document.createElement('div');
    // ReactDOM.render(<Locator map={map} />, locator);
    // map.controls[window.google.maps.ControlPosition.RIGHT_BOTTOM].push(locator);

    // const refresh = document.createElement('div');
    // ReactDOM.render(<Refresh map={map} />, refresh);
    // map.controls[window.google.maps.ControlPosition.BOTTOM_CENTER].push(refresh);
};

function Map() {
    const { center, city } = getParams();

    return (
        <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
            <GoogleMap
                center={center || defaults[city]}
                clickableIcons={false}
                mapContainerStyle={{ width: '100%', height: '100%' }}
                onLoad={onLoad}
                options={options}
                zoom={14}
            >
                <Refresh />
                <Locator />
                <Markers />
                <InfoWindows />
                <MyLocation />
                <BoundsListener />
            </GoogleMap>
        </LoadScript>
    );
}

function Markers() {
    const places = useFilteredResults();

    return places.map(place => (
        <Marker
            key={place.id}
            favorite={place.favorite}
            id={place.id}
            position={place.position}
            type={place.type}
        />
    ));
}

function InfoWindows() {
    const map = useGoogleMap();
    const places = useFilteredResults();
    const { selected, setSelected } = useSelection();
    const history = useHistory();
    const { search } = useLocation();

    useEffect(() => {
        const listener = map.addListener('click', () => {
            setSelected(null);
        });
        return () => window.google.maps.event.removeListener(listener);
    }, [map, setSelected]);

    if (!selected) {
        return null;
    }

    const place = places.find(place => place.id === selected);

    if (!place) {
        setSelected(null);
        return null;
    }

    return (
        <InfoWindow
            description={place.description}
            favorite={place.favorite}
            id={place.id}
            image={place.image}
            name={place.name}
            onClick={() => history.push(`/places/${selected}`, search)}
            position={place.position}
        />
    );
}

function BoundsListener() {
    const map = useGoogleMap();
    const { setBounds } = useResults();
    const { city } = getParams();
    const isInitialRenderRef = useRef(true);
    const cityRef = useRef(city);
    useEffect(() => {
        const listener = map.addListener('bounds_changed', () => {
            if (isInitialRenderRef.current || cityRef.current !== city) {
                setBounds(map.getBounds().toUrlValue());
                isInitialRenderRef.current = false;
                cityRef.current = city;
            }
        });
        return () => window.google.maps.event.removeListener(listener);
    }, [city, map, setBounds]);

    return null;
}

function getParams() {
    const params = new URLSearchParams(window.location.search);
    const city = params.get('city') || 'New York City';
    const center = params.get('center');
    if (center) {
        const [lat, lng] = center.split(',');
        return {
            center: {
                lat: parseFloat(lat),
                lng: parseFloat(lng)
            },
            city: city
        };
    }

    return { city };
}

export default React.memo(Map);
