import React from 'react';
import cx from 'classnames';
import { Icon } from '../';
import styles from './IconButton.module.css';

function IconButton({ iconName, noHover, onClick, variant }) {
    const className = cx(styles.root, {
        [styles.noHover]: noHover,
        [styles.transparent]: variant === 'transparent',
        [styles.white]: variant === 'white'
    });

    return (
        <button className={className} onClick={onClick}>
            <Icon iconName={iconName} />
        </button>
    );
}

export default IconButton;
