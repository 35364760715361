import { useMemo } from 'react';
import { useResults } from './';

function useOptions() {
    const { results } = useResults();

    return useMemo(() => ({
        city: ['New York City', 'San Francisco'],
        reviewed: ['Reviewed', 'Unreviewed'],
        food: unique(results.flatMap(result => result.tags.food)),
        drinks: unique(results.flatMap(result => result.tags.drinks)),
        activity: unique(results.flatMap(result => result.tags.activity))
    }), [results]);
}

function unique(array) {
    const set = new Set(array);
    return Array.from(set).sort();
}

export default useOptions;
