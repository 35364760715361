import React from 'react';
import { OverlayView } from '@react-google-maps/api';
import { FavoriteTag, Icon } from '../';
import { parse } from '../../util';
import styles from './InfoWindow.module.css';

function InfoWindow({ description, favorite, image, name, onClick, position }) {
    const preventHits = e => {
        if (e) {
            window.google.maps.OverlayView.preventMapHitsFrom(e);
        }
    };

    let dragging = false;
    const onMouseUp = () => {
        if (!dragging) {
            onClick && onClick();
        } else {
            dragging = false;
        }
    };

    return (
        <OverlayView mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET} position={position}>
            <div
                ref={preventHits}
                className={styles.root}
                onClick={onMouseUp}
                onMouseDown={() => dragging = false}
                onMouseMove={() => dragging = true}
            >
                {image && <img className={styles.image} alt={name} src={image} />}
                {favorite && <span className={styles.favorite}><FavoriteTag light /></span>}
                <div className={styles.text}>
                    <h2 className={styles.name}>{name}</h2>
                    <p className={styles.description}>{parse(description)}</p>
                    <span className={styles.link}>Read more <Icon iconName="arrowRight" /></span>
                </div>
            </div>
        </OverlayView>
    );
}

export default InfoWindow;
