import React from 'react';
import styles from './Menu.module.css';

function Menu({ children }, ref) {
    return (
        <div ref={ref} className={styles.root}>
            {children}
        </div>
    );
}

export default React.forwardRef(Menu);
