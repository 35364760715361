import React from 'react';
import cx from 'classnames';
import { parse, useSelection } from '../../util';
import { FavoriteTag, Image } from '../';
import styles from './ListItem.module.css';

function ListItem({ description, favorite, id, image, name }) {
    const { hovering, setHovering, selected, setSelected } = useSelection();

    const onClick = () => {
        setSelected(id);
    };

    const onHover = () => {
        setHovering(id);
    };

    const onHoverLeave = () => {
        setHovering(null);
    }

    return (
        <div
            className={cx(styles.root, { [styles.active]: hovering === id || selected === id })}
            onClick={onClick}
            onMouseOver={onHover}
            onMouseOut={onHoverLeave}
        >
            <div className={styles.inner}>
                <div className={styles.image}>
                    {image && <Image alt={name} height="100%" src={image} />}
                </div>
                <span className={styles.name}>{name}</span>
                <span className={styles.favorite}>{favorite && <FavoriteTag />}</span>
                <span className={styles.description}>{parse(description)}</span>
            </div>
        </div>
    );
}

export default ListItem;
