import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { useFilters, useOptions } from '../../util';
import { Button, Checkbox, Dropdown, Icon, MenuItem } from '../';
import styles from './Filter.module.css';

function MultiFilter({ id, isOpen, onClick, title }) {
    const { filters, set } = useFilters();
    const filter = filters[id];
    const options = useOptions()[id];
    const [state, setState] = useState(filter);

    useEffect(() => {
        setState(filter);
    }, [filter]);

    const canApply = !(state.length === filter.length &&
        state.every(value => filter.includes(value)));
    
    const icon = isOpen ? 'chevronUp' : 'chevronDown';
    const numSelected = filter.length;

    const onApply = () => {
        set(id, state);
        onClick(null);
    };
    const onOptionChanged = (option, value) => {
        if (value) {
            setState([...state, option]);
        } else {
            setState(state.filter(entry => entry !== option));
        }
    };

    return (
        <MenuItem>
            <span className={cx(styles.root, { [styles.active]: isOpen })} onClick={() => onClick(id)}>
                <span className={styles.title}>{title} <Icon iconName={icon} /></span>
                <span className={styles.selected}>{numSelected > 0 && `${numSelected} Selected`}</span>
            </span>
            <Dropdown isOpen={isOpen}>
                <div className={styles.dropdown}>
                    <Button disabled={!canApply} onClick={onApply} text="Apply" variant="secondary" block />
                    <div className={styles.selections}>
                        {
                            options.map((option) => (
                                <Checkbox
                                    key={option}
                                    isChecked={state.includes(option)}
                                    label={option}
                                    onChange={value => onOptionChanged(option, value)}
                                />
                            ))
                        }
                    </div>
                </div>
            </Dropdown>
        </MenuItem>
    );
}

export default MultiFilter;
