import React, { useContext, useEffect, useState } from 'react';
import { API_URL, useFilters } from './';

const cache = { bounds: "", results: [] };

const ResultsContext = React.createContext();

function ResultsProvider({ children }) {
    const [bounds, setBounds] = useState();
    const [results, setResults] = useState(cache.results);
    useEffect(() => {
        if (bounds && bounds !== cache.bounds) {
            fetch(API_URL + '/api/results?bounds=' + bounds)
            .then(res => res.json())
            .then(places => {
                places.forEach(place => {
                    place.unreviewed = place.tags.activity.includes('Unreviewed');
                    place.tags.activity = place.tags.activity.filter(activity => activity !== 'Unreviewed');
                });
                cache.bounds = bounds;
                cache.results = places;
                setResults(places);
            });
        }
    }, [bounds]);

    return (
        <ResultsContext.Provider value={{ results, setBounds }}>
            {children}
        </ResultsContext.Provider>
    );
}

function useResults() {
    return useContext(ResultsContext);
}

function useFilteredResults() {
    const { results } = useResults();
    const { filters } = useFilters();

    return results
        .filter(result => filters.reviewed === 'Unreviewed' ? result.unreviewed : !result.unreviewed)
        .filter(result => (filters.food.length === 0 && filters.drinks.length === 0) ||
            (filters.food.some(food => result.tags.food.includes(food)) || filters.drinks.some(drink => result.tags.drinks.includes(drink))))
        .filter(result => filters.activity.length === 0 || filters.activity.some(activity => result.tags.activity.includes(activity)));
}

export default useResults;
export { ResultsProvider, useFilteredResults };
