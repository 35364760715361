import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '../../components';
import { useViewport } from '../../util';
import styles from './LandingPage.module.css';

function LandingPage() {
    const viewport = useViewport();
    const history = useHistory();

    const onClick = () => history.push('/map');

    const buttonContainer = (
        <div className={styles.buttonContainer}>
            <Button onClick={onClick} text="Launch the Map" variant="primary" />
        </div>
    );

    return (
        <div className={styles.root}>
            <div className={styles.inner}>
                {viewport === 'mobile' && buttonContainer}
                <h2 className={styles.slug}>Hi, I'm Leland</h2>
                <p>I've always thought that the best way to experience a city is through its bars and restaurants. I started writing about these places when I moved to San Francisco and spent every Sunday at a new ramen bar trying to familiarize myself with the city. Writing was a way to remember where I'd been, and share my thoughts with friends. I have since expanded my repertoire beyond ramen, and moved to another city (New York), but in the process amassed a collection of over 1,000 places where I've had the good fortune of being a customer.</p>
                <p>Food and drink have a nice way of playing a supporting role in most things we do, and so I view this map just as much a journal as I do a catalogue of the best places to eat and drink. I hope you use this as a tool to figure out where to go next, whether you're on a first date, looking for a boozy brunch, or planning a dinner with your extended family. But most importantly, this map should make exploring the city easier. If you, like me, obsessively document where you eat and drink, I'd love to <a href="mailto:leland.chamlin@gmail.com">hear from you</a>.</p>
                {buttonContainer}
            </div>
        </div>
    );
}

export default LandingPage;
