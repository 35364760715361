import React, { useState } from 'react';
import { useQuery } from '../../util';
import { Dropdown } from '../';
import SearchBar from './SearchBar';
import SearchResult from './SearchResult';
import styles from './Search.module.css';

function Search({ onClose }) {
    const [query, setQuery] = useState('');
    const results = useQuery(query);

    return (
        <div className={styles.root}>
            <SearchBar onClose={onClose} onQueryChanged={setQuery} />
            <Dropdown isOpen={results.length !== 0} fullWidth>
                {
                    results.map(result => <SearchResult key={result.id} id={result.id} name={result.name} />)
                }
            </Dropdown>
        </div>
    );
}

export default Search;
