import React, { useEffect, useState } from 'react';
import { Marker } from '@react-google-maps/api';

import icon from '../../assets/my_location.svg';

function MyLocation() {
    const [position, setPosition] = useState();
    useEffect(() => {
        const listener = window.navigator.geolocation.watchPosition(position => setPosition({
            lat: position.coords.latitude,
            lng: position.coords.longitude
        }));
        return () => window.navigator.geolocation.clearWatch(listener);
    }, []);

    return position ? (
        <Marker
            icon={icon}
            position={position}
        />
    ) : null;
}

export default MyLocation;

