import React from 'react';
import cx from 'classnames';
import styles from './Tab.module.css';

function Tab({ active, name, onClick }) {
    return (
        <span className={cx(styles.root, { [styles.active]: active })} onClick={onClick}>
            {name}
        </span>
    );
}

export default Tab;
