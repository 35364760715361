import React from 'react';
import { Image } from '../';
import { parse } from '../../util';
import styles from './PlaceDescription.module.css';

function PlaceDescription({ description, image }) {
    return (
        <div className={styles.root}>
            <div className={styles.content}>
                <Image src={image} block />
                <p className={styles.description}>{parse(description)}</p>
            </div>
        </div>
    );
}

export default PlaceDescription;
