import React from 'react';
import cx from 'classnames';
import styles from './Dropdown.module.css';

function Dropdown({ children, fullWidth, isOpen }) {
    if (!isOpen) {
        return null;
    }
    
    return (
        <div className={cx(styles.root, { [styles.fullWidth]: fullWidth })}>
            {children}
        </div>
    );
}

export default Dropdown;
