import React, { useEffect } from 'react';
import { Marker as GoogleMarker, useGoogleMap } from '@react-google-maps/api';
import { useSelection } from '../../util';

import bar from '../../assets/bar.svg';
import bar_favorite from '../../assets/bar_favorite.svg';
import bar_hover from '../../assets/bar_hover.svg';
import cafe from '../../assets/cafe.svg';
import cafe_favorite from '../../assets/cafe_favorite.svg';
import cafe_hover from '../../assets/cafe_hover.svg';
import restaurant from '../../assets/restaurant.svg';
import restaurant_favorite from '../../assets/restaurant_favorite.svg';
import restaurant_hover from '../../assets/restaurant_hover.svg';

const icons = {
    bar: {
        default: bar,
        favorite: bar_favorite,
        hover: bar_hover
    },
    cafe: {
        default: cafe,
        favorite: cafe_favorite,
        hover: cafe_hover
    },
    restaurant: {
        default: restaurant,
        favorite: restaurant_favorite,
        hover: restaurant_hover
    }
}

function Marker({ favorite, id, position, type = 'restaurant' }) {
    const map = useGoogleMap();
    const { hovering, setHovering, selected, setSelected } = useSelection();
    useEffect(() => {
        if (selected === id) {
            map.panTo(position);
            map.panBy(0, 175);
        }
    }, [id, map, position, selected]);

    const icon = {
        regular: icons[type][favorite ? 'favorite' : 'default'],
        hover: icons[type].hover
    };

    const onClick = () => {
        setSelected(selected === id ? null : id);
    }

    const onHover = () => {
        setHovering(id);
    }

    const onHoverLeave = () => {
        setHovering(null);
    }

    return (
        <GoogleMarker
            icon={hovering === id || selected === id ? icon.hover : icon.regular}
            onClick={onClick}
            onMouseOver={onHover}
            onMouseOut={onHoverLeave}
            position={position}
        />
    );
}

export default Marker;
