import React, { useContext, useState } from 'react';

const SelectionContext = React.createContext();

function SelectionProvider({ children }) {
    const [hovering, setHovering] = useState(null);
    const [selected, setSelected] = useState(null);
    const value = {
        hovering,
        setHovering,
        selected,
        setSelected
    };

    return (
        <SelectionContext.Provider value={value}>
            {children}
        </SelectionContext.Provider>
    );
}

function useSelection() {
    return useContext(SelectionContext);
}

export default useSelection;
export { SelectionProvider };
