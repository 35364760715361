import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useGoogleMap } from '@react-google-maps/api';
import { Icon } from '../';
import { useResults } from '../../util';
import styles from './Controls.module.css';

export function Locator() {
    const map = useGoogleMap();
    const ref = useRef();
    const [position, setPosition] = useState();
    useEffect(() => {
        if (ref.current) {
            const node = ref.current;
            map.controls[window.google.maps.ControlPosition.RIGHT_BOTTOM].push(node);
            return () => {
                const index = map.controls[window.google.maps.ControlPosition.RIGHT_BOTTOM]
                    .getArray()
                    .findIndex(control => control === node);
                map.controls[window.google.maps.ControlPosition.RIGHT_BOTTOM].removeAt(index);
            };
        }
    }, [map, position]);
    useEffect(() => {
        const listener = window.navigator.geolocation.watchPosition(position => setPosition({
            lat: position.coords.latitude,
            lng: position.coords.longitude
        }));
        return () => window.navigator.geolocation.clearWatch(listener);
    }, []);

    if (!position) {
        return null;
    }

    return (
        <div className={styles.locator} onClick={() => map.panTo(position)} ref={ref}>
            <Icon iconName="locator" />
        </div>
    );
};

export function Refresh() {
    const map = useGoogleMap();
    const ref = useRef();
    const history = useHistory();
    useEffect(() => {
        if (ref.current) {
            const node = ref.current;
            map.controls[window.google.maps.ControlPosition.BOTTOM_CENTER].push(node);
            return () => {
                const index = map.controls[window.google.maps.ControlPosition.BOTTOM_CENTER]
                    .getArray()
                    .findIndex(control => control === node);
                map.controls[window.google.maps.ControlPosition.BOTTOM_CENTER].removeAt(index);
            };
        }
    }, [map]);
    const { setBounds } = useResults();
    const onClick = () => {
        setBounds(map.getBounds().toUrlValue());
        const params = new URLSearchParams(window.location.search);
        params.set('center', map.getCenter().toUrlValue());
        history.replace({ search: params.toString() });
    }

    return (
        <div className={styles.refresh} onClick={onClick} ref={ref}>
            <Icon iconName="refresh" />
            <span className={styles.text}>Search here</span>
        </div>
    );
}

export function Zoom({ map }) {
    return (
        <div className={styles.zoom}>
            <div onClick={() => map.setZoom(map.getZoom() + 1)}><Icon iconName="plus" /></div>
            <div onClick={() => map.setZoom(map.getZoom() - 1)}><Icon iconName="minus" /></div>
        </div>
    );
};
