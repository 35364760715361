import React from 'react';
import cx from 'classnames';
import styles from './Checkbox.module.css';

function Checkbox({ isChecked, label, labelFirst, onChange }) {
    const handleChange = e => onChange && onChange(e.target.checked);

    return (
        <label className={cx(styles.root, { [styles.labelFirst]: labelFirst })}>
            <input
                className={styles.checkbox}
                type="checkbox"
                checked={isChecked}
                onChange={handleChange}
            />
            <span className={styles.label}>{label}</span>
        </label>
    );
}

export default Checkbox;
