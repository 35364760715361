import React from 'react';
import Tab from './Tab';

function Pivot({ active, onChange, options }) {
    return (
        <span>
            {
                options.map(option => (
                    <Tab
                        key={option.id}
                        active={active === option.id}
                        name={option.name}
                        onClick={() => onChange(option.id)}
                    />
                ))
            }
        </span>
    );
}

export default Pivot;
