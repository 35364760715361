import React from 'react';

import { ReactComponent as Dark } from '../../assets/favorite_dark.svg';
import { ReactComponent as Light } from '../../assets/favorite_light.svg';

function FavoriteTag({ light = false }) {
    return React.createElement(light ? Light : Dark);
}

export default FavoriteTag;
