import React from 'react';
import { Filters, List, Map } from '../../components';
import { useViewport, SelectionProvider, useFilters } from '../../util';
import MobileMapPage from './MobileMapPage';
import styles from './MapPage.module.css';

function MapPage() {
    const { filters } = useFilters();
    const viewport = useViewport();

    if (viewport === 'mobile') {
        return <MobileMapPage />;
    }

    return (
        <div className={styles.root}>
            <div className={styles.filters}>
                <Filters />
            </div>
            <SelectionProvider>
                <div className={styles.list}>
                    <List />
                </div>
                <div className={styles.map}>
                    <Map city={filters.city} />
                </div>
            </SelectionProvider>
        </div>
    );
}

export default MapPage;
