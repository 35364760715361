import { useEffect, useState } from 'react';

const getViewport = () => {
    const { innerWidth: width } = window;
    return width <= 960 ? 'mobile' : 'desktop';
};

function useViewport() {
    const [viewport, setViewport] = useState(getViewport);
    useEffect(() => {
        const onResize = () => {
            const newViewport = getViewport();
            if (newViewport !== viewport) {
                setViewport(newViewport);
            }
        };

        window.addEventListener('resize', onResize);
        return () => window.removeEventListener('resize', onResize);
    }, [viewport]);

    return viewport;
}

export default useViewport;
