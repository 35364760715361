import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Header, ThemeContainer } from './components';
import { LandingPage, MapPage, PlacePage } from './pages';
import { ResultsProvider } from './util';
import styles from './App.module.css';

function App() {
    useEffect(() => {
        const resizeListener = () => {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        };
        resizeListener();
        window.addEventListener('resize', resizeListener);
        return () => window.removeEventListener('resize', resizeListener);
    }, []);

    return (
        <Router>
            <ThemeContainer>
                <div className={styles.root}>
                    <Header />
                    <div className={styles.content}>
                        <Switch>
                            <Route path="/" exact>
                                <LandingPage />
                            </Route>
                            <Route path="/map" exact>
                                <ResultsProvider>
                                    <MapPage />
                                </ResultsProvider>
                            </Route>
                            <Route path="/places/:id" exact>
                                <PlacePage />
                            </Route>
                        </Switch>
                    </div>
                </div>
            </ThemeContainer>
        </Router>
    );
}

export default App;
