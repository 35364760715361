import React from 'react';
import styles from './MenuItem.module.css';

function MenuItem({ children, onClick }) {
    return (
        <span className={styles.root} onClick={onClick}>
            {children}
        </span>
    );
}

export default MenuItem;
