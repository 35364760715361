import React, { useState } from 'react';
import cx from 'classnames';
import { filterNames, useFilters, useOptions } from '../../util';
import { Checkbox, Icon, RadioButton } from '../';
import styles from './MobileFilters.module.css';

function MobileFilters({ onClose }) {
    const { clear, filters, set } = useFilters();
    const options = useOptions();
    const [open, setOpen] = useState(filterNames[0].id);
    const onOpenFilter = filter => {
        if (filter !== open) {
            setOpen(filter);
        }
    };

    const variant = filterNames.find(filter => filter.id === open).variant;

    return (
        <div className={styles.root}>
            <div className={styles.header}>
                <span onClick={onClose}>
                    <Icon iconName="chevronLeft" />
                    <span style={{verticalAlign: 'middle'}}>Map</span>
                </span>
                <span className={styles.title}>
                    <span>Filter Results</span>
                </span>
                <span className={styles.clear}>
                    <span onClick={clear}>Clear Filters</span>
                </span>
            </div>
            <div className={styles.content}>
                <div className={styles.filters}>
                    {
                        filterNames.map(filter => (
                            <span
                                key={filter.id}
                                className={cx(styles.filter, { [styles.active]: filter.id === open })}
                                onClick={() => onOpenFilter(filter.id)}
                            >
                                <span className={styles.filterName}>{filter.variant === 'single' ? filters[filter.id] : filter.title}</span>
                                {filter.variant !== 'single' && filters[filter.id].length !== 0 && <span className={styles.selected}>{filters[filter.id].length} Selected</span>}
                            </span>
                        ))
                    }
                </div>
                <div className={styles.options}>
                    {
                        options[open].map(option => variant === 'single' ? (
                            <span key={option} className={styles.option}>
                                <RadioButton
                                    isChecked={filters[open] === option}
                                    label={option}
                                    onChange={() => set(open, option)}
                                />
                            </span>
                        ) : (
                            <span key={option} className={styles.option}>
                                <Checkbox
                                    isChecked={filters[open].includes(option)}
                                    label={option}
                                    onChange={value => value ? set(open, [...filters[open], option]) : set(open, filters[open].filter(entry => entry !== option))}
                                    labelFirst
                                />
                            </span>
                        ))
                    }
                </div>
            </div>
        </div>
    );
}

export default MobileFilters;
